<template>
    <div class="auth-container">

            <div class="card">

                <div class="card-body">
                    <h3 class="card-title text-center">Create an Account</h3>

                    <div class="form-group">
                        <input placeholder="Name" class="form-control" v-model="name" />
                    </div>

                    <div class="form-group">
                        <input placeholder="Email Address" class="form-control" v-model="email" />
                    </div>
                    <div class="form-group">
                        <password-field @enterPressed="createAccount" v-model="password"></password-field>
                    </div>

                    <p class="mt-0 small text-muted fw-light">
                        By signing up you agree to UpContent's
                        <a target="_blank" :href="termsOfServiceUrl" class="ux-privacy-terms-link" @click.stop>
                            Terms </a>
                        and
                        <a target="_blank" :href="privacyPolicyUrl" class="ux-privacy-privacy-link" @click.stop>
                            Privacy</a>
                        Statements.
                    </p>
                    <div class="d-flex align-items-center small">
                        <button :disabled="networkActivity || ! passesValidation" @click="createAccount" class="btn btn-primary">Sign Up</button>
                        <a href="#" @click.prevent="goToLoginPage" v-if="!iToken" class="btn btn-sm btn-link ms-auto">Sign In</a>
                        <button v-if="iToken" class="btn btn-default ms-auto" @click="$emit('cancel')">Back</button>
                    </div>

                    <div class="alert alert-blue" v-if="networkActivity">
                        {{networkActivity}}
                    </div>

                    <div class="alert alert-danger" v-if="errorMessage">
                        {{errorMessage}}
                    </div>

                </div>
            </div>

    </div>
    <AuthFooter/>
</template>

<script>

import Base from './Base'
import PasswordField from '@/components/Auth/PasswordField'
import {mapGetters} from "vuex";
import AuthFooter from "@/components/Auth/AuthFooter.vue";

export default {
    mixins : [Base],
    components : {
        AuthFooter,
        PasswordField
    },
    computed: {
        ...mapGetters({
            privacyPolicyUrl: 'privacyPolicyUrl',
            termsOfServiceUrl: 'termsOfServiceUrl',
        })
    },
    watch : {
        email(){
            this.errorMessage = '';
        }
    },
    mounted(){
        if (this.$route.query && this.$route.query.plan){
            this.$store.dispatch('setUserSignUpPlan', {plan : this.$route.query.plan});
        }
    },
    methods : {
        goToLoginPage(){
            if (this.name && this.email){
                let x = confirm("Leave this screen and go to the login page? You haven't signed up yet.");
                if ( ! x ){
                    return;
                }
            }

            this.$router.push('/login');
        }
    }
}
</script>
