/* eslint-disable no-unused-vars */
import {StringUtils} from "@/utilities/Strings";
import {OAuthConnection} from "./OAuth";

export class SniplyConnection extends OAuthConnection{
    constructor(context) {
        super('sniply', context);
    }
    async fetchCampaigns(team, campaignId, paging = 1) {
        // let url = 'https://snip.ly/api/v2/campaigns';
        // let data = this.apiData(team);
        // let bearerToken = data&&data.token;
        // let response = await fetch(url, {
        //     method: 'GET',
        //     headers: {
        //         "Authorization": `Bearer ${bearerToken}`,
        //         'Content-Type': 'text/plain'
        //     }
        // });
        let currentItemResponse = paging === 1 && campaignId ? await this.$api.get(`/accounts/${team.id}/sniply/campaigns/${campaignId}`) : null;
        let response = await this.$api.get(`/accounts/${team.id}/sniply/ctas?page=${paging}`);
        // let fetchPage = (page) => this.fetchCampaigns(team, `?page=${page}`);
        // let pageCount = Math.ceil(response.data.count / 10);
        return {
            count: response.data.count,
            // pageCount, fetchPage,
            // pages: new Array(pageCount).map((x, i) => ({ index: i, number: i+1, fetch: ()=>fetchPage(i)})),
            next: () => this.fetchCampaigns(team, campaignId, response.data.next ? ++paging : null),
            hasNext() { return !!response.data.next; },
            prev: () => this.fetchCampaigns(team, response.data.prev),
            hasPrev() { return !!response.data.prev; },
            results: [...response.data.results, ...(currentItemResponse ? [{...currentItemResponse.data, type: 'campaign'}] : [])]
        }
    }
    async fetchBrands(team, paging) {
        // let url = 'https://snip.ly/api/v2/campaigns';
        // let data = this.apiData(team);
        // let bearerToken = data&&data.token;
        // let response = await fetch(url, {
        //     method: 'GET',
        //     headers: {
        //         "Authorization": `Bearer ${bearerToken}`,
        //         'Content-Type': 'text/plain'
        //     }
        // });

        let response = await this.$api.get(`/accounts/${team.id}/sniply/brands${paging?paging:""}`);
        // let fetchPage = (page) => this.fetchCampaigns(team, `?page=${page}`);
        // let pageCount = Math.ceil(response.data.count / 10);
        return {
            count: response.data.count,
            // pageCount, fetchPage,
            // pages: new Array(pageCount).map((x, i) => ({ index: i, number: i+1, fetch: ()=>fetchPage(i)})),
            next: () => this.fetchBrands(team, response.data.next),
            hasNext() { return !!response.data.next; },
            prev: () => this.fetchBrands(team, response.data.prev),
            hasPrev() { return !!response.data.prev; },
            results: response.data.results
        }
    }
    fetchCollectionSettings(collection) {
        return this.$api.get('/collections/'+collection.id+'/sniply-settings')
    }
    connectUrl(){
        return `https://snip.ly/api/authenticate/?client_id=${process.env.VUE_APP_SNIPLY_CLIENT_ID}&redirect_uri=${this.returnUrl()}`
    }

    // dataFromQuery(query) {
    //     let { state, code } = query;
    //     return { state, code }, r;
    // }

    authError(query) {
        return query.error;
    }
    // This may be needed as a hack fix
    // returnUrl() {
    //     let currentProtocol = window.location.protocol;
    //     let domain = window.location.hostname;
    //     let isLocal = domain.startsWith('localhost');
    //     let protocol = isLocal ? currentProtocol : 'https:';
    //     let isQa = domain.includes('qa');
    //     let host = isLocal ? 'localhost:8080' : isQa ? 'my.qa.upcontent.com' : 'my.qa.upcontent.com';
    //     return `${protocol}//${host}/apis/${this.apiName}/authorize`
    // }
}
