export const buffer = {
    key: 'buffer',
    label: 'Buffer',
    template: ({link, text}) =>
        `https://publish.buffer.com/compose?text=${text}&url=${link}`
}


export const funnelAmplified = {
    key: 'funnelAmplified',
    label: 'Funnel Amplified',
    template: ({link, text}) =>
        `https://app.funnelamplified.com/social_posts/new?url=${link}&text=${text}`
}


export const hubspot = {
    key: 'hubspot',
    label: 'HubSpot',
    template: ({link, text}, { hub_id }) =>{
        let client = 'upcontent';
        let origin = 'https%3A%2F%2Fmy.qa.upcontent.com'
        let query = `clientTag=${client}&topOrigin=${origin}&body=${text}&link=${link}`
        return `https://app.hubspot.com/broadcast-editor/${hub_id}?${query}`
    },
    func({contentItem, type}){
        window.Events.$emit('hubspotShare', {contentItem, type})
        return true;
    }
};


export const hootsuite = {
    key: 'hootsuite',
    label: 'Hootsuite',
    func({text, link}) {
        let features = window.$store.getters.accountFeatures;
        let newCompose = features.newHootsuiteCompose;
        if (newCompose && window.hsp && window.hsp.composeMessageV2) {
            window.hsp.composeMessageV2(`${text} ${link}`, {
                scheduleTimestamp: false
            })
            return true;
        } else if (window.hsp && window.hsp.composeMessage) {
            window.hsp.composeMessage(`${text} ${link}`)
            return true;
        } else {
            return false;
        }
    },
    template: ({link, text}) => {
        return `https://hootsuite.com/hootlet/social-share?url=${link}&title=${text}`
    }
}
