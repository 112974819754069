<template>
    <div class="auth-container" style="flex-direction: column;">

            <div class="alert alert-blue" v-if="loginMessage">
                {{loginMessage}}
            </div>

            <div class="card">

                <div class="card-body">
                    <h3 class="card-title text-center">Sign in to your Account</h3>
                    <div class="form-group">
                        <input type="email" placeholder="Email Address" class="form-control" v-model="email" />
                    </div>
                    <div class="form-group" v-if="enableDu">
                        <input type="email" placeholder="Test Field" class="form-control" v-model="du" />
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" class="form-control" v-model="password" @keyup.enter="logIn" />
                    </div>

                    <div class="d-flex small align-items-center">
                        <button :disabled="networkActivity" @click="logIn" class="btn btn-primary">Sign In</button>
                        <router-link v-if="! iToken" to="/sign-up" class="btn btn-link btn-sm ms-auto">Sign up</router-link>
                        <button v-if="iToken" class="btn btn-default ms-auto" @click="$emit('cancel')">Back</button>
                        
                        <router-link :target="target" to="/forgot-password" class="btn btn-link btn-sm ms-3">Forgot password</router-link>
                    </div>

                    <div class="alert alert-blue" v-if="networkActivity">
                        {{networkActivity}}
                    </div>

                    <div class="alert alert-danger" v-if="errorMessage">
                        {{errorMessage}}
                    </div>

                </div>
            </div>
            
    </div>
    <AuthFooter/>
</template>

<script>
import Base from './Base'
import { mapGetters } from 'vuex'
import AuthFooter from "@/components/Auth/AuthFooter.vue";

export default {
    components: {AuthFooter},
    mixins : [Base],
    data(){
        return {
        }
    },
    computed : {
        target(){
            if (this.iToken){
                return '_blank';
            }
        },
        ...mapGetters({
            loginMessage : 'loginMessage',
            features: 'features'
        }),
        enableDu() {
            return this.features.du;
        }
    },

    mounted(){
        this.$api.bustCache();
    },
    methods : {
        
    }
}
</script>
