const filterMap = {
    'contentType' : 'type',
    'age' : 'age',
    'evergreen': 'undated',
    'sortBy' : 'sort',
    'minFullTextLength': 'min_full_text_length',
}

export function filtersToPost(filters, showingFavoriteSuggestions, includeAllSources=true, includeNewSources=false) {
    let query = {};
    if (filters){
        for (var key in filterMap){
            let varName = filterMap[key]
            var value = filters[key]
            if (typeof value == 'string'){
                value = value.toLowerCase();
            }
            query = {...query, [varName]: value}
        }
    }

    if (!includeAllSources) {
        if (includeNewSources) {
            let excludedSources = filters.excludedSources || [];
            query = {...query, excludes:excludedSources.join(',')}
        } else {
            let sources = filters.sources || [];
            query = {...query, sources:sources.join(',')}
        }
    }

    return query;
}

function formatArrayValue(v) {
    if (!v) return '';
    v = String(v)
    let encoded = encodeURIComponent(v);
    let quoated = v.includes(',') ? `"${encoded}"` : encoded;
    return quoated
}

export function filterQueryString(filters, showingFavoriteSuggestions, includeAllSources=true, includeNewSources=false, limit=100){
    var query = '';

    if (filters){
        for (var key in filterMap){
            let varName = filterMap[key]
            var value = filters[key]
            if (typeof value == 'string'){
                value = value.toLowerCase();
            }
            if (Array.isArray(value)){
                value =  (value.map(formatArrayValue).join(','))
            }
            query += `${varName}=${value}&`
        }
    }

    if (!includeAllSources) {
        if (includeNewSources) {
            let excludedSources = filters.excludedSources || [];
            query += `excludes=${excludedSources.map(formatArrayValue).join(',')}&`
        } else {
            let sources = filters.sources || [];
            query += `sources=${sources.map(formatArrayValue).join(',')}&`
        }
    }


    if (showingFavoriteSuggestions){
        query += "status=saved&"
    }

    query += `limit=${limit||100}`

    return query;
}
export function feedUrl(type, id, filters, showingFavoriteSuggestions, includeAllSources=true, includeNewSources=false){
    if (type)
        return `https://feeds.qa.upcontent.com/suggestions?${type}=${id}&${filterQueryString(filters, showingFavoriteSuggestions, includeAllSources, includeNewSources)}`
}
export function feedUrlBas(type, id) {
    return `https://feeds.qa.upcontent.com/suggestions?${type}=${id}`
}
